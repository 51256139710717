h2 {
  margin-top: 30px; }

.nav {
  &_sub &_sub {
    margin-top: -50px;
    top: auto;
    left: calc(100% + 5px); }
  &_open + &_sub > &_sub::before {
    top: 20%;
    left: -0.5rem;
    transform: rotate(-90deg); }
  &_open + &_sub > &_sub > &_child:first-child::before {
    border: none; }
  &_open + &_sub &_child {
    padding-top: 0; } }

.homepage_recent_posts {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row; } }

.homepage_more_posts {
  display: flex;
  justify-content: center;
  padding-top: 20px; }
